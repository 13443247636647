<template>
  <b-modal
    :id="idModalCreate"
    size="lg"
    :title="type==='add'?'Thêm ngành nghề kinh doanh':'Chỉnh sửa ngành nghề kinh doanh'"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateBusinessSector"
    @hidden="resetState"
  >
    <b-row class="mb-1">
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <b-form-group>
          <label for="InputHelp">Mã cấp 1</label>
          <b-form-input
            id="InputHelp"
            v-model="queryBody.level1"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <b-form-group>
          <label for="InputHelp">Mã cấp 2</label>
          <b-form-input
            id="InputHelp"
            v-model="queryBody.level2"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <b-form-group>
          <label for="InputHelp">Mã cấp 3</label>
          <b-form-input
            id="InputHelp"
            v-model="queryBody.level3"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <b-form-group>
          <label for="InputHelp">Mã cấp 4</label>
          <b-form-input
            id="InputHelp"
            v-model="queryBody.level4"
          />
        </b-form-group>
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
      >
        <b-form-group>
          <label for="InputHelp">Mã cấp 5</label>
          <b-form-input
            id="InputHelp"
            v-model="queryBody.level5"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-form-group>
          <label for="InputHelp">Tên ngành nghề kinh doanh <span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
            :custom-messages="customCode"
          >
            <b-form-input
              id="InputHelp"
              v-model="queryBody.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <b-form-group>
      <label for="InputHelp">Ngành nghề kinh doanh cha </label>
      <business-sector-select
        v-model="queryBody.parentId"
        :parentId="parentId"
      />
    </b-form-group>

    <b-form-group>
      <label for="InputHelp">Mô tả</label>
      <b-form-textarea
        id="InputHelp"
        v-model="queryBody.description"
        rows="3"
        no-resize
      />
    </b-form-group>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BusinessSectorSelect from './BusinessSectorSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    BusinessSectorSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
  },
  props: {
    idModalCreate: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataBusinessSectorId: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      queryBody: {
        level1: '',
        level2: '',
        level3: '',
        level4: '',
        level5: '',
        name: '',
        parentId: '',
        description: '',
      },
      dataDetailStrcut: null,
      customCode: {
        required: 'Tên ngành nghề kinh doanh',
      },
      customMessages: {
        required: 'Ngành nghề kinh doanh cha',
      },
    }
  },
  watch: {
    dataBusinessSectorId: {
      deep: true,
      handler(val) {
        if (val && this.type === 'edit') {
          this.queryBody = {
            level1: this.dataBusinessSectorId.level1,
            level2: this.dataBusinessSectorId.level2,
            level3: this.dataBusinessSectorId.level3,
            level4: this.dataBusinessSectorId.level4,
            level5: this.dataBusinessSectorId.level5,
            name: this.dataBusinessSectorId.name,
            parentId: this.dataBusinessSectorId.parentId,
            description: this.dataBusinessSectorId.description,
          }
        }
        if (val && this.type === 'add') {
          this.queryBody = {
            level1: '',
            level2: '',
            level3: '',
            level4: '',
            level5: '',
            name: '',
            parentId: this.dataBusinessSectorId.id,
            description: '',
          }
        }
      },
    },
  },
  methods: {
    // ...mapActions('struct', ['doCreateOrganizationStruct']),
    handleCreateBusinessSector(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('handleCreateBusinessSector', this.queryBody, this.$refs.rule)
    },
    resetState() {
      this.queryBody = {
        level1: '',
        level2: '',
        level3: '',
        level4: '',
        level5: '',
        name: '',
        parentId: '',
        description: '',
      }
    },
  },
}
</script>

<style>

</style>
